import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
import gamesPerStat from "../../js/gamesPerStat"
import percentageOf from "../../js/percentageOf"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class HatTricksPage extends React.Component {

  state = {
    showTopLevel: false,
  };

  manageTopLevelToggle = () => {
    this.setState(state => ({ showTopLevel: !state.showTopLevel }));
  };

  render() {

    const pageTitle = "Hat Tricks"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const mHTdata = this.props.data.allSheetMessiHatTricks.edges
    const rHTdata = this.props.data.allSheetRonaldoHatTricks.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / mdata.filter(d => d.node.competition === comp).map(d => d.node.hatTricks)[0]).toFixed(1)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / rdata.filter(d => d.node.competition === comp).map(d => d.node.hatTricks)[0]).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "All Time Career",
        "mSubStat": subStat('m', 'All Time Career'),
        "rSubStat": subStat('r', 'All Time Career'),
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Career Europe",
        "title": "All Time Career *",
        "mSubStat": subStat('m', 'All Time Career Europe'),
        "rSubStat": subStat('r', 'All Time Career Europe'),
        "btnText": "Yearly Breakdown",
        "topLevel": true
      },
      {
        "comp": "All Time Club",
        "mSubStat": subStat('m', 'All Time Club'),
        "rSubStat": subStat('r', 'All Time Club'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Club Europe",
        "title": "All Time Club *",
        "mSubStat": subStat('m', 'All Time Club Europe'),
        "rSubStat": subStat('r', 'All Time Club Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "League",
        "mSubStat": subStat('m', 'All Time League'),
        "rSubStat": subStat('r', 'All Time League'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time League Europe",
        "title": "League *",
        "mSubStat": subStat('m', 'All Time League Europe'),
        "rSubStat": subStat('r', 'All Time League Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'All Time Europe'),
        "rSubStat": subStat('r', 'All Time Europe'),
        "btnText": "Season Breakdown",
        "topLevel": "both"
      },
      {
        "comp": "Other Club Cups",
        "mSubStat": subStat('m', 'All Time Cups'),
        "rSubStat": subStat('r', 'All Time Cups'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Cups Europe",
        "title": "Other Club Cups *",
        "mSubStat": subStat('m', 'All Time Cups Europe'),
        "rSubStat": subStat('r', 'All Time Cups Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "International",
        "mSubStat": subStat('m', 'All Time Internationals'),
        "rSubStat": subStat('r', 'All Time Internationals'),
        "btnText": "Yearly Breakdown",
        "topLevel": "both"
      },
    ]

    let [{
      hatTricks: mAllTimeHatTricks,
      apps: mAllTimeApps,
      head: mAllTimeHead,
    }] = mdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)
  
    let [{
      hatTricks: rAllTimeHatTricks,
      apps: rAllTimeApps,
    }] = rdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)

    // Number of hat-tricks that needed a penalty goal
    const mHTPens = mHTdata.filter(d => (d.node.goals-d.node.pens) >= 3).length
    const rHTPens = rHTdata.filter(d => (d.node.goals-d.node.pens) >= 3).length

    // Number of hat-tricks that needed an extra time goal
    const mHTET = mHTdata.filter(d => (d.node.goals-d.node.extratime) >= 3).length
    const rHTET = rHTdata.filter(d => (d.node.goals-d.node.extratime) >= 3).length

    // Number of hat-tricks that include 1 or more left foot goal
    const mHTLeft = mHTdata.filter(d => d.node.left > 0).length
    const rHTLeft = rHTdata.filter(d => d.node.left > 0).length

    // Number of hat-tricks that include 1 or more right foot goal
    const mHTRight = mHTdata.filter(d => d.node.right > 0).length
    const rHTRight = rHTdata.filter(d => d.node.right > 0).length

    // Number of hat-tricks that include 1 or more headed goal
    const mHTHead = mHTdata.filter(d => d.node.head > 0).length
    const rHTHead = rHTdata.filter(d => d.node.head > 0).length

    // Number of hat-tricks that include 1 or more outside box (open play) goal
    const mHTOutside = mHTdata.filter(d => d.node.outside > 0).length
    const rHTOutside = rHTdata.filter(d => d.node.outside > 0).length

    // Number of hat-tricks that include 1 or more FK goal
    const mHTFK = mHTdata.filter(d => d.node.freeKick > 0).length
    const rHTFK = rHTdata.filter(d => d.node.freeKick > 0).length

    // Number of hat-tricks scored at home/away/neutral
    const mHTHome = mHTdata.reduce((total, d) => { return total + (d.node.homeAway === "H" ? 1 : 0) }, 0)
    const mHTAway = mHTdata.reduce((total, d) => { return total + (d.node.homeAway === "A" ? 1 : 0) }, 0)
    const mHTNeutral = mHTdata.reduce((total, d) => { return total + (d.node.homeAway === "N" ? 1 : 0) }, 0)
    const rHTHome = rHTdata.reduce((total, d) => { return total + (d.node.homeAway === "H" ? 1 : 0) }, 0)
    const rHTAway = rHTdata.reduce((total, d) => { return total + (d.node.homeAway === "A" ? 1 : 0) }, 0)
    const rHTNeutral = rHTdata.reduce((total, d) => { return total + (d.node.homeAway === "N" ? 1 : 0) }, 0)

    return (

      <Layout>
        <SEO
          title={`${pageTitle} - Messi vs Ronaldo Career Hat-tricks and Complete List of All Hat-tricks`}
          description={`Unlike most players, hat tricks are a common occurence for Messi and Ronaldo, but who has the most? And who has hit a hat trick more regularly over their career?`}
          canonicalPath={`/detailed-stats/hat-tricks/`}
        />

        <h1>{pageTitle} <span className="sr-only">- Messi vs Ronaldo Career Hat-tricks</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-2" onChange={this.manageTopLevelToggle}>
          <label className={`flex items-center cursor-pointer px-3 sm:px-5 pt-3 pb-4 bg-noir-lighten10 border ${this.state.showTopLevel ? 'border-highlight' : 'border-noir-lighten10'}`}>
            <input type="checkbox" checked={this.state.showTopLevel ? true : false} />
            <span className="ml-2 text-xs sm:text-sm">Only include club stats for European clubs</span>
          </label>
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              title={d.title ? d.title : d.comp}
              topLevel={d.topLevel}
              showTopLevel={this.state.showTopLevel}
              playerTitleHidden={`${d.comp} ${pageTitle}`}
              statType={pageTitle}
              btnText={d.btnText}
              statView={['Totals', "Frequency"]}
              mSubStat={`scores a hat trick every ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} games`}
              rSubStat={`scores a hat trick every ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} games`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-', 'lowWins')}
            />
          ))}

        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo Hat-tricks</h2>

          <InfoBar />
          
          <p>At the peak of their powers, it seemed like Messi and Ronaldo were scoring hat-tricks every other week. They've scored a mind-boggling {Number(mAllTimeHatTricks)+Number(rAllTimeHatTricks)} hat-tricks between them in their glittering careers.</p>

          <p>Of those, <strong className="text-messi">Messi has scored {mAllTimeHatTricks} hat-tricks</strong> and <strong className="text-ronaldo">Ronaldo has scored {rAllTimeHatTricks} hat-tricks</strong>.</p>

          <p>Having played considerably fewer games, Messi has scored hat-tricks with slightly more regularity over his career. <strong className="text-messi">Messi scores a hat-trick every {gamesPerStat(mAllTimeHatTricks, mAllTimeApps)} games</strong> on average, whereas <strong className="text-ronaldo">Ronaldo scores a hat-trick for every {gamesPerStat(rAllTimeHatTricks, rAllTimeApps)} games played</strong>.</p>

          <h3>4 or 5 Goals in a Game</h3>

          <p><strong className="text-messi">Messi</strong> has scored more than 3 goals in a game on 8 separate occasions, scoring <strong className="text-messi">4 goals six times</strong>, and <strong className="text-messi">5 goals twice</strong>.</p>

          <h4>Messi 4 goals in a game</h4>
          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>H/A</th>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Competition</th>
                </tr>
              </thead>
              <tbody>
              {mHTdata.filter(d => d.node.goals === '4').map(
                d => (
                  <tr key={d.node.date+d.node.opponent}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <h4>Messi 5 goals in a game</h4>
          <div className="w-full overflow-x-scroll scrolling-touch text-sm mb-8">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>H/A</th>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Competition</th>
                </tr>
              </thead>
              <tbody>
              {mHTdata.filter(d => d.node.goals === '5').map(
                d => (
                  <tr key={d.node.date+d.node.opponent}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <p><strong className="text-ronaldo">Ronaldo</strong> has scored more than 3 in a game an impressive 11 times, scoring <strong className="text-ronaldo">4 goals in a game nine times</strong> and <strong className="text-ronaldo">5 goals twice</strong>.</p>

          <h4>Ronaldo 4 goals in a game</h4>
          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>H/A</th>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Competition</th>
                </tr>
              </thead>
              <tbody>
              {rHTdata.filter(d => d.node.goals === '4').map(
                d => (
                  <tr key={d.node.date+d.node.opponent}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <h4>Ronaldo 5 goals in a game</h4>
          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>H/A</th>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Competition</th>
                </tr>
              </thead>
              <tbody>
              {rHTdata.filter(d => d.node.goals === '5').map(
                d => (
                  <tr key={d.node.date+d.node.opponent}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <h3>How many of their hat-tricks include penalties?</h3>

          <p>Of Messi's {mAllTimeHatTricks} career hat-tricks, {mAllTimeHatTricks-mHTPens} of them included penalties, meaning <strong className="text-messi">{parseFloat((Number(mAllTimeHatTricks-mHTPens) / Number(mAllTimeHatTricks)) * 100).toFixed(1)}% of all his hat-tricks included a penalty</strong>.</p>

          <p>Ronaldo scored a penalty in {rAllTimeHatTricks-rHTPens} of his {rAllTimeHatTricks} career hat-tricks, meaning <strong className="text-ronaldo">{parseFloat((Number(rAllTimeHatTricks-rHTPens) / Number(rAllTimeHatTricks)) * 100).toFixed(1)}% of his hat-tricks have included a penalty goal</strong>.</p>

          <h3>Unique Hat-tricks</h3>

          <p>Cristiano Ronaldo has scored an unbelievable <strong className="text-ronaldo">10 "perfect" hat-tricks</strong> (a hat-trick consisting of 1 right foot goal, 1 left foot goal, and 1 headed goal), clearly demonstrating his superb weak foot and heading ability.</p>
          
          <p>With Messi only scoring {mAllTimeHead} headers in his whole career, it may come as no surprise that he hasn't got any perfect hat-tricks to his name.</p>

          <p>Leo Messi does however have a <strong className="text-messi">weak foot hat-trick</strong> to his name, scoring 3 right footed goals in a 2014 Champions League tie against APOEL. Messi has also scored a <strong className="text-messi">dead ball hat-trick</strong>, consisting of a penalty and 2 direct free kick goals against Celta Vigo in 2019.</p>

          <p>Although Ronaldo hasn't quite managed a weak foot hat-trick, an incredible <strong className="text-ronaldo">{rHTLeft} of his {rAllTimeHatTricks} hat-tricks ({percentageOf(rHTLeft, rAllTimeHatTricks, 1)}%) have included a weak foot goal</strong>. For Messi, it's <strong className="text-messi">{mHTRight} out of {mAllTimeHatTricks} ({percentageOf(mHTRight, mAllTimeHatTricks, 1)}%)</strong>.</p>

          <h3>How do they score their hat-tricks?</h3>

          <table>
            <thead>
              <tr>
                <th className="text-left">Hat-tricks WITH...</th>
                <th>Messi (%)</th>
                <th>Ronaldo (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">Weak Foot Goal</td>
                <td>{mHTRight} ({percentageOf(mHTRight, mAllTimeHatTricks, 1)}%)</td>
                <td>{rHTLeft} ({percentageOf(rHTLeft, rAllTimeHatTricks, 1)}%)</td>
              </tr>
              <tr>
                <td className="text-left">Headed Goal</td>
                <td>{mHTHead} ({percentageOf(mHTHead, mAllTimeHatTricks, 1)}%)</td>
                <td>{rHTHead} ({percentageOf(rHTHead, rAllTimeHatTricks, 1)}%)</td>
              </tr>
              <tr>
                <td className="text-left">Free Kick Goal</td>
                <td>{mHTFK} ({percentageOf(mHTFK, mAllTimeHatTricks, 1)}%)</td>
                <td>{rHTFK} ({percentageOf(rHTFK, rAllTimeHatTricks, 1)}%)</td>
              </tr>
              <tr>
                <td className="text-left">Outside Box Goal (open play)</td>
                <td>{mHTOutside} ({percentageOf(mHTOutside, mAllTimeHatTricks, 1)}%)</td>
                <td>{rHTOutside} ({percentageOf(rHTOutside, rAllTimeHatTricks, 1)}%)</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th className="text-left">Hat-tricks WITHOUT...</th>
                <th>Messi (%)</th>
                <th>Ronaldo (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">Penalty Goal</td>
                <td>{mHTPens} ({percentageOf((mHTPens), mAllTimeHatTricks, 1)}%)</td>
                <td>{rHTPens} ({percentageOf((rHTPens), rAllTimeHatTricks, 1)}%)</td>
              </tr>
              <tr>
                <td className="text-left">Extra Time Goal</td>
                <td>{mHTET} ({percentageOf((mHTET), mAllTimeHatTricks, 1)}%)</td>
                <td>{rHTET} ({percentageOf((rHTET), rAllTimeHatTricks, 1)}%)</td>
              </tr>
            </tbody>
          </table>

          <h3>Where do they score their hat-tricks?</h3>

          <p>{mHTAway} of Messi's {mAllTimeHatTricks} career hat-tricks have come away from home; {parseFloat((Number(mHTAway) / Number(mAllTimeHatTricks)) * 100).toFixed(1)}%. He's scored {mHTHome} hat-tricks at home and {mHTNeutral} in a neutral venue.</p>

          <ul className="list-disc list-inside text-messi">
            <li>{parseFloat((Number(mHTHome) / Number(mAllTimeHatTricks)) * 100).toFixed(1)}% hat-tricks scored at home</li>
            <li>{parseFloat((Number(mHTAway) / Number(mAllTimeHatTricks)) * 100).toFixed(1)}% hat-tricks scored away</li>
          </ul>

          <p>{rHTAway} of Ronaldo's {rAllTimeHatTricks} career hat-tricks have been scored in away matches; {parseFloat((Number(rHTAway) / Number(rAllTimeHatTricks)) * 100).toFixed(1)}%. He's scored {rHTHome} hat-tricks in home games and {rHTNeutral} at a neutral venue.</p>

          <ul className="list-disc list-inside text-ronaldo">
            <li>{parseFloat((Number(rHTHome) / Number(rAllTimeHatTricks)) * 100).toFixed(1)}% hat-tricks scored at home</li>
            <li>{parseFloat((Number(rHTAway) / Number(rAllTimeHatTricks)) * 100).toFixed(1)}% hat-tricks scored away</li>
          </ul>

          <h3>Notable Messi Hat-tricks</h3>

          <p><strong className="text-messi">Messi</strong> began his hat-trick haul with a bang, netting his first ever treble in <strong className="text-highlight">El Clasico</strong> against arch-rivals <strong className="text-highlight">Real Madrid</strong> at 19 years of age. When a Guti free kick flicked in off Sergio Ramos' head, Real looked likely to secure a huge victory, but Messi's superb 91st minute strike ensure the game finished 3-3. Interestingly, this is the only match in which Messi has scored a hat-trick that his side hasn't won.</p>

          <p>Another that sticks in the memory is Messi's hat-trick in <strong className="text-highlight">World Cup qualifying</strong> against <strong className="text-highlight">Ecuador</strong>. Argentina had endured a turbulent campaign and required a win in their final game - away to Ecuador - to ensure qualification for Russia 2018. Ecuador scored an early goal and Argentina feared the worst, but then, Messi happened. He struck twice inside the first half an hour to turn the game around and then sealed his hat-trick, along with World Cup qualification, in the second half.</p>

          <p>Messi has 8 hat-tricks in the <strong className="text-highlight">Champions League</strong>, which is a record he and Ronaldo currently share. His two most memorable in the competition were against <strong className="text-highlight">Arsenal</strong> and Bayer Leverkusen. Messi produced a simply stunning performance against Arsenal at Camp Nou in 2010, hitting 4 past the English side to mark his very first Champions League hat-trick.</p>
          
          <p>Two years later, he somehow bettered this by scoring FIVE against <strong className="text-highlight">Bayer Leverkusen</strong> in a Last 16 encounter, becoming the first player in history to score 5 in a Champions League fixture (since matched by Luiz Adriano).</p>

          <p>In a 2019 La Liga fixture, Messi scored one of his more unique hat-tricks against <strong className="text-highlight">Celta Vigo</strong>, when an early penalty followed by 2 free kicks either side of half-time secured a <strong className="text-highlight">dead ball hat-trick</strong>.</p>

          <h3>Complete List of Messi Hat-tricks</h3>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>H/A</th>
                  <th>Team</th>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Competition</th>
                  <th>Goals</th>
                  <th>Pens</th>
                </tr>
              </thead>
              <tbody>
              {mHTdata.map(
                d => (
                  <tr key={d.node.date+d.node.opponent}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.team}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                    <td>{d.node.goals}</td>
                    <td>{d.node.pens}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <h3>Notable Ronaldo Hat-tricks</h3>

          <p><strong className="text-ronaldo">Ronaldo</strong> has scored an incredible {rdata.filter(d => d.node.competition === "All Time Internationals").map(d => d.node.hatTricks)} hat-tricks at international level, two of which particularly stand out.</p>

          <p>The first was a crucial <strong className="text-highlight">World Cup qualification</strong> play-off, in which Portugal took a 1-0 first-leg lead to <strong className="text-highlight">Sweden</strong>. After Ronaldo's opener in the second-leg, Ibrahimovic gave Sweden a 2-1 lead as they threatened to turn the tie around. Then, Ronaldo struck twice in three minutes to secure his hat-trick, and qualification for the 2014 World Cup. His third strike also took him to 47 international goals, equalling Pauleta's all time record.</p>

          <p>The second Portugal hat-trick that sticks out is of course his <strong className="text-highlight">World Cup</strong> treble against <strong className="text-highlight">Spain</strong>. With Spain largely the better side, Ronaldo salvaged an unlikely draw for Portugal as he fired in a late free-kick to level the match at 3-3. In terms of quality, it's perhaps generous to rank this among his best hat-tricks (one being a penalty and another a goalkeeping howler from De Gea), but on the world stage against one of the best teams in the world, and with the pressure weighing heavy on that late free-kick, it has to be considered one of his most memorable.</p>

          <p>In 2016, Real Madrid were in real trouble after going down 2-0 to <strong className="text-highlight">Wolfsburg</strong> in their <strong className="text-highlight">Champions League</strong> quarter-final first-leg. But they needn't have worried. In the second-leg, Ronaldo wiped out their lead in the space of two first half minutes, before scoring a third in the 77th minute to secure Real's semi-final spot.</p>

          <p>A year later, he was back at it again. <strong className="text-highlight">Bayern Munich</strong> had forced their quarter-final tie with Real Madrid into extra-time. Ronaldo then struck twice in extra-time to seal his hat-trick and Real's advancement in the competition.</p>

          <p>He wasn't finished their though. In the following round he bacme the first player to score back-to-back hat-tricks in the Champions League knockout stage, putting 3 past fierce rivals <strong className="text-highlight">Atletico Madrid</strong>.</p>

          <p>Two years later, Ronaldo reinforced his reputation once again for rescuing his sides in the Champions League. This time he was in Juventus colours, with his side 2-0 down after a Last 16 first-leg against <strong className="text-highlight">Atletico Madrid</strong>. In the second-leg... you can guess what happened. Ronaldo netted 2 headers and a penalty to completely turn the tie around, scoring his first Juventus hat-trick in the process. It was also his eighth hat-trick in the Champions League, equalling Messi's competition record.</p>

          <h3>Complete List of Ronaldo Hat-tricks</h3>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>H/A</th>
                  <th>Team</th>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Competition</th>
                  <th>Goals</th>
                  <th>Pens</th>
                </tr>
              </thead>
              <tbody>
              {rHTdata.map(
                d => (
                  <tr key={d.node.date+d.node.opponent}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.team}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                    <td>{d.node.goals}</td>
                    <td>{d.node.pens}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <p>If you’re looking for more hat-trick stats, contact us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we’ll do our best to help.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          head
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          hatTricks
        }
      }
    }
    allSheetMessiHatTricks {
      edges {
        node {
          date
          homeAway
          team
          opponent
          score
          competition
          season
          goals
          pens
          left
          right
          head
          outside
          freeKick
          extratime
        }
      }
    }
    allSheetRonaldoHatTricks {
      edges {
        node {
          date
          homeAway
          team
          opponent
          score
          competition
          season
          goals
          pens
          left
          right
          head
          outside
          freeKick
          extratime
        }
      }
    }
  }
`

export default HatTricksPage
